<template>
  <div
    class="bg-white text-[#404040] font-body-new -mb-8 pb-8  min-h-screen h-full container space-y-6 w-full"
  >
    <div class="flex items-center">
      <h2 class="font-bold text-4xl mr-5">Payments Summary</h2>
    </div>
    <main class="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 items-start place-content-start gap-y-8 gap-x-12 text-[#1A1F1A] w-full mx-auto">
      <div class="space-y-6">
        <p class="text-xs font-[400] text-justify"> Take advantage of our special offer and choose the payment option that works best for you!</p>

        <p class="text-xs font-[400] text-justify">
          <strong>
            Option 1: Pay Now and Save
          </strong>
          <ul class="list-disc text-xs list-outside ml-4">
            <li>
              Get 50% Off: Pay for your certificate now and receive a 50% discount on the total fee.
            </li>
          </ul>
        </p>
        <p class="text-xs font-[400] text-justify">
          <strong>
            Option 2: Pay Later
          </strong>
          <ul class="list-disc text-xs list-outside ml-4">
            <li>
              Full Payment Required: If you choose to pay later, you will need to pay the full amount.
            </li>
            <li>
              Deposit: Start with a deposit of {{ user?.country_id == 156 ? "1000 NGN" : "5 USD" }} to get started.
            </li>
            <li>
              Balance Payment: You can pay the remaining balance later.
            </li>
          </ul>
        </p>
        <div class="flex items-center">
          <p class="text-xs font-semibold">Select the option that suits you!</p>
        </div>
      </div>
      <div>
        <div class="bg-[#F8FCF8] rounded-md p-2 border border-[#B5DAB4]">
            <table class="table-auto w-full border-separate border-spacing-y-2 text-xs font-semibold">
                <tr>
                    <td>Course Fee (100% off)</td>
                    <td class="text-right line-through px-2">
                        <span class="font-sans">
                            {{ user?.country_id == 156 ? "&#8358;" : "$" }}
                        </span>
                        {{ integerFormatter(tuition_fee) }}
                    </td>
                </tr>
                <tr>
                    <td>Mandatory Certificate Fee </td>
                    <td class="text-right font-sans">
                        {{ user?.country_id == 156 ? "&#8358;" : "$" }} {{ full_payment }} 
                    </td>
                </tr>
                <tr>
                    <td class="border-t pt-2 border-[#BBDDBB]">Total</td>
                    <td class="font-sans text-right pt-2 border-t border-[#BBDDBB]">
                        {{ user?.country_id == 156 ? "&#8358;" : "$" }} {{ full_payment }}
                    </td>
                </tr>
            </table>
        </div>
        <div class="space-y-3 mt-3" v-if="user?.batchDiscount?.batch_id == user?.batch?.batch_id">
          <button @click="proceedToPayment('full')" class="hover:shadow w-full py-2 px-4 rounded-md bg-primary-green text-white font-semibold text-sm">Pay Now (50% Off): {{ user?.country_id == 156 ? "&#8358;" : "$" }} {{ discount_payment }}</button>
          <button @click="proceedToPayment('part')" class="hover:shadow w-full py-2 px-4 rounded-md border border-primary-green text-primary-green bg-white font-semibold text-sm">Pay later: {{ user?.country_id == 156 ? "&#8358;" : "$" }} {{ full_payment }}</button>
        </div>
        <div class="space-y-3 mt-3" v-else>
          <button @click="$router.push({ name: 'ApplicationPayment', params: { slug: $route.params.slug } })" class="hover:shadow w-full py-2 px-4 rounded-md bg-primary-green text-white font-semibold text-sm">Pay Now : {{ user?.country_id == 156 ? "&#8358;" : "$" }} {{ full_payment }}</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";
</script>

<script>
import { mapGetters } from 'vuex'
import Auditing from "./AuditCourse.vue";

export default {
    data(){
      return {
        certificate_fee_ngn: 5000,
        certificate_fee_usd: 25,
      }
    },
    components: {
      Auditing
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
      tuition_fee() {
        return this.user?.country_id == 156 ? this.user?.batch?.course?.price : Math.ceil(this.user?.batch?.course?.price / 200);
      },
      full_payment(){
        return this.user?.country_id == 156 ? this.certificate_fee_ngn : this.certificate_fee_usd;
      },
      discount_payment(){
        return this.user?.country_id == 156 ? Math.ceil(this.certificate_fee_ngn / 2) :Math.ceil(this.certificate_fee_usd / 2);
      }
    },
    methods: {
      proceedToPayment(type){
        this.$router.push({ 
            name: 'ApplicationPayment', 
            params: { slug: this.$route.params.slug },
            query: {
              type
            }
        })
      }
    }

}
</script>

<style>

</style>